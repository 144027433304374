/* eslint-disable */
import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import HubspotForm from '../components/Form/HubspotForm'

import EmployeesWorking from '../images/people/employees-working.jpg'
import EmployeesWorkingMobile from '../images/people/employees-working-mobile.jpg'

import {
  Billboard,
  Column,
  Columns,
  LinkButton,
  Typography,
  VariableContent,
} from '@leshen/gatsby-theme-leshen'
import { Layout } from '@leshen/gatsby-theme-contentful'

import header from '../components/Header'
import footer from '../components/Footer'
import Footer from '../components/Footer'
import footerData from '../data/footerData'
import headerData from '../data/headerData'

const Home = () => {
  const layoutProps = {
    data: {
      contentfulPage: {
        header: header(),
        footer: footer(),
        seo: {
          title: 'Partner With Us | Clearlink',
          description:
            'If you’re looking for a trusted partner to deliver real results at every stage of customer acquisition, let’s talk!',
          canonical: 'https://clearlink.com/',
          robots: 'follow,index',
        },
        path: '/',
        promoCode: 'SAMPLEPROMO',
        ringPool: 'RINGPOOL123',
        hideStickyCTA: false,
        spanish: false,
        sections: [
          {
            // Add any relevant section data
          },
        ],
      },
      site: {
        siteMetadata: {
          siteURL: 'https://clearlink.com',
          siteName: 'Clearlink',
          alternateName: 'Clearlink',
          removeTrailingSlashes: true,
        },
      },
    },
    titleTemplate: 'Clearlink',
    defaultRobots: 'follow,index',
    header: header(),
    footer: footer(),
    main: (
      <>
        <Billboard
          backgroundColor="dark"
          alignImageToBottom
          mobileAlignImageToBottom
          variant="full"
          className="mobile-image-full no-padding-bottom-mobile content-transparent-desktop"
          image={
            <img
              src={EmployeesWorking}
              alt="a pair of employees sit in conference room and smile as they hold a meeting"
            />
          }
          mobileImage={
            <img
              src={EmployeesWorkingMobile}
              alt="a pair of employees sit in conference room and smile as they hold a meeting"
            />
          }
          mainContent={
            <>
              <div
                data-sal="slide-right"
                data-sal-delay="100"
                data-sal-duration="500"
                data-sal-easing="ease"
              >
                <Typography variant="h1">
                  Grow with partners you can trust
                  <span className="blue-punctuation-large">.</span>
                </Typography>
                <Typography className="mb-32">
                  Our experts deliver results at every stage of the buying
                  process. From research and decision to leads and acquisition,
                  customer retention, and beyond—together we’ll take your
                  business where you want it to go.
                </Typography>
                <LinkButton
                  variant="hero"
                  to="#hubpsot-form-section"
                  color="primary"
                >
                  Start the conversation{' '}
                </LinkButton>
              </div>
            </>
          }
        />

        <VariableContent
          backgroundColor="primary"
          alignMainContent="center"
          mainContent={
            <>
              <div
                data-sal="slide-up"
                data-sal-duration="500"
                data-sal-easing="ease"
              >
                <Typography variant="h2">
                  We stand by our work
                  <span className="white-punctuation-large">.</span>
                </Typography>
                <Typography variant="body">
                  Your success is our success. We are incentivized to grow with
                  you, so we work hand-in-hand to deploy tools and strategies
                  that will see your business thrive.
                </Typography>
              </div>
            </>
          }
        >
          <div
            data-sal="slide-up"
            data-sal-duration="500"
            data-sal-easing="ease"
          >
            <Columns leftAligned={true}>
              <Column backgroundColor="white">
                <Typography variant="h4">Ownership</Typography>
                <Typography variant="body">
                  Partnering with Clearlink means that you have control over
                  every step of the user journey. We’ll help you own the
                  entirety of the marketing funnel from a customer’s very first
                  interaction till long after they’ve decided to purchase.
                </Typography>
              </Column>
              <Column backgroundColor="white">
                <Typography variant="h4">Insight</Typography>
                <Typography variant="body">
                  Our robust data and technology suite means you’ll have access
                  to in-depth customer information, real-time campaign results,
                  and proprietary sales statistics to take your business to the
                  next level.
                </Typography>
              </Column>
              <Column backgroundColor="white">
                <Typography variant="h4">Expertise</Typography>
                <Typography variant="body">
                  Our marketing experts have decades of experience in the
                  industry. We’ve cultivated partnerships with some of the
                  biggest companies in a variety of verticals and are proud of
                  what we’ve accomplished.
                </Typography>
              </Column>
            </Columns>
          </div>
        </VariableContent>

        <HubspotForm
          heading={`Let's talk about a partnership`}
          punctuation="."
        />
      </>
    ),
  }

  return <Layout {...layoutProps} />
}
export default Home
